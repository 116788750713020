/* You can add global styles to this file, and also import other style files */
.bg-text {
    text-shadow: 2px 2px 2px #673AB7;
    color: #80c1ff;
    font-size: x-large;
    font-weight: bold;
}

.bg-text-color-1 {
    color: #ffffff;
    font-size: medium;
    font-weight: bold;
}

.bg-text-color-2 {
    color: #80c1ff;
    font-size: medium;
    font-weight: bold;
}

.responsive-image {
    width: 100%;
    height: auto;
}

.icon-loading {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    height: 20%;
}

.grecaptcha-badge {
    visibility: hidden !important;
}